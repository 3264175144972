
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});

    var printColumns = [
      { field: "Name" },
      { field: "Discount" },
      { field: "Ordinal" },
      { field: "StartTime" },
      { field: "Description" },
      { field: "Remark" },
    ];

    const gridOptions: GridOptions = {
      id: "brand",
      title: "品牌",
      multiselect: false,
      toolbarConfig: { custom: true, refresh: true },
      printConfig: { sheetName: "品牌清單", columns: printColumns, modes: ["current", "selected", "all"] },
      exportConfig: { filename: "品牌清單", type: "csv", types: ["html", "csv"], mode: "all", modes: ["current", "selected", "all"], columns: printColumns },
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Country.Name", title: "國家", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Discount', title: '基礎折扣', showHeaderOverflow: true, showOverflow: true, align: "right", width: 100, sortable: true, resizable: false }
      ],
      decideRowOperable: (row, operation) => row.DataMode !== 4 || operation === 'read',
      promises: {
        query: model ? (params) => model.dispatch("brand/query", params) : undefined,
        queryAll: model ? () => model.dispatch("brand/query") : undefined,
        save: model ? (params) => model.dispatch("brand/save", params) : undefined,
      },
      modalConfig: { width: 600, showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        { field: "Name", title: "名稱", span: 12, itemRender: { name: "$input", props: { placeholder: "請輸入名稱" } } },
        { field: "CountryId", title: "國家", span: 12, slots: { default: "column-country-id" } },
        { field: "Discount", title: "基礎折扣", span: 12, itemRender: { name: "$input", props: { type: "float", min: 0, max: 1, step: 0.1, placeholder: "請輸入折數" } } },
        { field: "Ordinal", title: "排序", span: 12, itemRender: { name: "$input", props: { type: "number", placeholder: "請輸入數值" } } },
        { field: "StartTime", title: "開始時間", span: 12, itemRender: { name: "$input", props: { type: "date", placeholder: "請輸入日期" } } },
        { field: "EndTime", title: "結束時間", span: 12, itemRender: { name: "$input", props: { type: "date", placeholder: "請輸入日期" } } },
        { field: "Description", title: "說明", span: 24, itemRender: { name: '$textarea', props: { placeholder: '請輸入說明', clearable: true }, attrs: { type: 'text' } } },
        { field: "Remark", title: "備註", span: 24, itemRender: { name: '$textarea', props: { placeholder: '請輸入備註', clearable: true }, attrs: { type: 'text' } } },
      ],
      rules: {
        Name: [{ required: true }],
        Discount: [{ type: "number", required: true, message: "基礎折扣" }],
        StartTime: [{ required: true }],
      }
    };

    const countrySelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇國家',
      textField: 'Name',
      valueField: 'Id',
      formatText: async (row: any) => {
        let entity = await model!.dispatch("country/find", row.Id); // eslint-disable-line
        let text = entity.Name
        while (entity.Parent) {
          entity = entity.Parent
          text = `${entity.Name}>${text}`
        }
        return text
      },
      columns: [
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true, treeNode: true }
      ],
      promises: {
        find: (value) => model!.dispatch("country/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("country/query", params) // eslint-disable-line
      },
    }

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      countrySelectOptions
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    }
  }
});
